<template>
  <section class="Category">
    
    <div class="page-header">
      <h3 class="page-title">Category</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link class="btn btn-primary btn-fw" :to="`/catalog/add-category/0`"
            >Add Category
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title" style="min-height: 68px">
            <div class="row">
              <div class="col-5"></div>
              <div class="col-7">
                <div class="float-right" v-if="modelId">
                  <a
                    v-on:click="deleteFile(modelId)"
                    v-b-tooltip.hover
                    title="Delete"
                    class="btn btn btn-add btn-outline-primary"
                  >
                    <i class="mdi mdi-delete"> </i>
                  </a>

                  <router-link
                    class="btn btn btn-add btn-outline-primary"
                    v-b-tooltip.hover
                    title="Edit Category"
                    :to="`/catalog/edit-category/${modelId}`"
                  >
                    <i class="mdi mdi-pencil"> </i>
                  </router-link>
                  <router-link
                    class="btn btn btn-add btn-outline-primary"
                    :to="`/catalog/add-category/${modelId}`"
                    v-b-tooltip.hover
                    title="Add Sub Category"
                  >
                    <i class="mdi mdi-plus"> </i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-tabs align="right">
                <b-tab active class="p-0">
                  <template #title>
                    <div class="tab-icon"><i class="fa fa-th-large"></i></div>
                  </template>
                  <div class="row project-list-showcase" v-if="data">
                    <div class="col-12 pb-2">
                      <small class="text-primary"
                        >Please click any root category to create a subcategory, edit a
                        Category or delete a category
                      </small>
                    </div>
                    <div class="col-md-5">
                      <vue-drag-tree
                        :data="data"
                        @current-node-clicked="curNodeClicked"
                        @drag-end="dragEndHandler"
                        @drop="dropHandler"
                      >
                      </vue-drag-tree>
                    </div>
                    <div class="col-md-7">
                      <div class="card-body" v-if="selectCategory == ''">
                        <p class="text-center">
                          Please click any category. Then view the details for that
                          category
                        </p>
                      </div>
                      <div class="card-body" v-if="selectCategory">
                        <div class="project-grid d-flex border mb-3">
                          <div class="user-avatar mb-auto">
                            <img
                              v-if="selectCategory.image_id"
                              :src="`${imageApiURL}/${info.thumb_s}`"
                              class="profile-img img-lg"
                            />
                            <img
                              v-else
                              src="@/assets/images/dummy.jpg"
                              alt=""
                              class="profile-img img-lg"
                            />
                          </div>
                          <div class="wrapper pl-3 pt-3 w-100">
                            <div class="wrapper d-flex align-items-center">
                              <div class="wrapper" v-if="selectCategory.name">
                                <h5 class="project-title pb-0 mb-0">
                                  {{ selectCategory.name }}
                                </h5>
                              </div>
                              <div
                                class="badge badge-success ml-auto text-capitalize float-right mr-2"
                                v-if="selectCategory.active"
                              >
                                {{ selectCategory.active }}
                              </div>
                            </div>
                            <div
                              class="wrapper d-flex align-items-center font-weight-medium text-muted pt-2"
                            >
                              <p class="mb-0 font-size-12">
                                {{ selectCategory.description }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="project-grid border">
                          <h4
                            class="card-title p-2"
                            block
                            v-b-toggle.accordion-1
                            style="font-size: 14px !important"
                          >
                            Products in Category
                            <i class="mdi mdi mdi-plus menu-icon float-right"></i>
                          </h4>

                          <b-collapse
                            id="accordion-1"
                            accordion="my-accordion"
                            role="tabpanel"
                          >
                            <div class="table-responsive w-100 mb-0">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody
                                  y
                                  v-for="product in selectCategory.products"
                                  :key="product.id"
                                >
                                  <tr>
                                    <td>
                                      <router-link
                                        class="text-dark"
                                        :to="`/catalog/edit_category_product/${product.id}`"
                                        >{{ product.name }}
                                      </router-link>
                                    </td>
                                    <td>
                                      {{
                                        product.price.toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                class="col-12 p-3 text-center font-size-12"
                                v-if="selectCategory.products == ''"
                              >
                                No Products found this Category
                              </div>
                            </div>
                          </b-collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 P-3 text-center" v-if="data == ''">
                      No data found!
                    </div>
                  </div>
                </b-tab>

                <b-tab class="p-0">
                  <template #title>
                    <div class="tab-icon"><i class="fa fa-list-ul"></i></div>
                  </template>
                  <div class="row">
                    <div class="col-md-12 pr-2 pl-2">
                      <div class="table-responsive mb-0 border">
                        <table class="table table-centered table-nowrap">
                          <thead>
                            <tr>
                              <th>Categories</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody v-if="data">
                            <tr v-for="item in data" :key="item.id">
                              <td>{{ item.name }}</td>
                              <td>
                                <router-link
                                  class="mr-3 text-info"
                                  :to="`/catalog/edit-category/${item.id}`"
                                >
                                  <i class="mdi mdi-pencil font-size-18"></i>
                                </router-link>
                                <!-- <a v-on:click="deleteFile(item.id)" class="text-primary">
                                  <i class="mdi mdi-window-close font-size-18"></i>
                                </a> -->
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="col-12 p-3 text-center" v-if="data == ''">
                          No data found!
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { catalogApi } from "../../../api";

const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import axios from "axios";
export default {
  name: "view_category",
  components: {
    
  },
  data() {
    return {
      data: [],
      
      modelId: 0,
      selectId: "",
      imageApiURL,
      edit_Category: {
        parent_id: "",
      },
      selectCategory: "",
      info: "",
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      
      const { data } = await catalogApi.allCategory();
      this.data = data;
      
    },
    async curNodeClicked(model, component) {
      this.modelId = model.id;
      this.selectId = model.id;
      this.categoryById();
    },

    async categoryById() {
      
      const { data } = await catalogApi.categoryById(this.selectId);
      data.forEach((value, index) => {
        this.selectCategory = value;
        if (this.selectCategory.image_id) {
          this.categoryImageById();
        }
        if (this.selectCategory.active === 1) {
          this.selectCategory.active = "active";
        } else {
          this.selectCategory.active = "inactive";
        }
      });
      
    },
    categoryImageById() {
      

      axios.get(`${mediaApi}/files/${this.selectCategory.image_id}`).then((response) => {
        this.info = response.data.data[0];
        
      });
    },

    dropHandler(model, component, e) {
      if (model.id) {
        this.edit_Category.parent_id = model.id;
      } else if (model.id == "") {
        this.edit_Category.parent_id = 0;
      } else {
        this.edit_Category.parent_id = 0;
      }
    },
    dragEndHandler(model, component, e) {
      this.$swal({
        title: "Are you sure?",
        text: "this operation  can take a long time!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          

          const response = catalogApi
            .updateCategory(model.id, {
              ...this.edit_Category,
            })
            .then((response) => {
              
              if (response.data.code == 200) {
                if (result.value) {
                  this.$swal("Moved!", "Your Category has been Moved.", "success");
                }
              }
            });
        }
      });
      if (model.id == "") {
        this.edit_Category.parent_id = 0;
      }
    },

    deleteFile(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          
          const response = catalogApi.deleteCategory(id).then((response) => {
            
            if (response.data.code == 200) {
              this.list();
              if (result.value) {
                this.$swal("Deleted!", "Your file has been deleted.", "success");
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.btn-add {
  padding: 5px 8px !important;
  border: 1px solid #ebedf2 !important;
  margin: 0px 0px 0px 4px;
  border-radius: 2px;
  box-shadow: none;
}

.btn.btn-fw {
  min-width: 125px;
  padding: 11px 11px;
  font-weight: 500;
}

.product-media::before {
  padding-top: 25%;
  height: 200px !important;
  border-radius: 4px 4px 0 0;
}
.badge-success {
  padding: 3px 7px 4px;
  font-size: 10px;
  font-weight: 600;
}
</style>
